import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { CustomDialog } from "../../../components/modal/CustomDialog";
import { FAQDataType } from "../../../types/global.types";
import { createFAQ, deleteFAQ, getFAQ, updateFAQ, updateOrder } from "../../../apis/faq";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { Loader } from "../../../components/Loader";
import { DragCollapsibleList } from "../../../components/CallapsibleList/DragCollapsibleList";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const updatedItems = (lists: FAQDataType[], newItem: FAQDataType) => {
  return lists.map((item) => {
    if (item.id === newItem.id) {
      return {
        ...item,
        question: newItem.question,
        answer: newItem.answer,
      };
    }
    return item;
  });
};

export const Faq = () => {
  const [items, setItems] = useState<FAQDataType[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [newItem, setNewItem] = useState<FAQDataType>();
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean | false>(true);

  const { showSnackBar } = useGlobalSnackBar();

  const currentUser = useSelector((state: RootState) => state.adminAuth.admin);

  const fetchFAQs = async () => {
    try {
      const FAQData = await getFAQ();
      setItems(FAQData);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch admins", error);
    }
  };

  useEffect(() => {
    fetchFAQs();
  }, []);

  const editQuestion = (e: any, item: FAQDataType) => {
    e.preventDefault();
    e.stopPropagation();
    setNewItem(item);
    setOpenModal(true);
    setIsEditModal(true);
  };

  const deleteQuestion = (e: any, item: FAQDataType) => {
    e.preventDefault();
    e.stopPropagation();
    setNewItem(item);
    setOpenDeleteModal(true);
  };

  const permissionErrorMessage = () => {
    showSnackBar("Invalid operation: Only Administrators can make this change.", "error");
  };

  const handleDeleteItem = async () => {
    if (currentUser?.permission !== "Administrator") {
      permissionErrorMessage();
      return;
    }

    if (!newItem) return;
    try {
      await deleteFAQ(newItem);
      setItems((prevItems) =>
        prevItems.filter((prevItem) => prevItem.id !== newItem.id)
      );
      setOpenDeleteModal(false);
      showSnackBar("Deleted this FAQ");
    } catch (err) {
      showSnackBar("Server error", "error");
    }
  };

  const handleAddItem = async () => {
    if (currentUser?.permission !== "Administrator") {
      permissionErrorMessage();
      return;
    }

    if (!newItem) return;
    if (!isEditModal) {
      // create new
      const createNewItem = items;
      try {
        const res = await createFAQ(newItem);
        createNewItem.push(res);
        setItems(createNewItem);
        showSnackBar("Created new FAQ");
      } catch (err) {
        showSnackBar("Server error", "error");
      }
    } else {
      // update FAQ
      try {
        await updateFAQ(newItem);
        const updateItem = updatedItems(items, newItem);
        setItems(updateItem);
        showSnackBar(`Updated this FAQ`);
      } catch (err) {
        showSnackBar("Server error", "error");
      }
    }
    setNewItem(undefined);
    setOpenModal(false);
    setIsEditModal(false);
  };

  const onChangeFAQ = (txt: string, type: "answer" | "question") => {
    setNewItem((prevNewItem: any) => ({
      ...prevNewItem,
      [type]: txt,
    }));
  };

  const setOrder = async (reorderedItems: any) => {
    const transformedData = reorderedItems?.map((item: any, index: number) => ({
      id: item.id,
      order: index
    }));
    setItems(reorderedItems)

    try {

      await updateOrder(transformedData)

    } catch (err) {
      console.error(err)
    }
  }

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2} justifyContent="space-between" mb={2}>
          <Grid item xs={2} />
          <Grid item xs={2}>
            <Card
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => {
                setOpenModal(true);
                setIsEditModal(false);
              }}
            >
              <CardContent sx={{ pb: "10px !important" }}>
                <Typography variant="h5" component="div">
                  +
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  FAQ
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <DragCollapsibleList
          editQuestion={editQuestion}
          deleteQuestion={deleteQuestion}
          items={items}
          setOrder={setOrder}
        />

        <CustomDialog
          openModal={openModal}
          handleClose={() => setOpenModal(false)}
          handleAction={handleAddItem}
          buttonTitle={!isEditModal ? "Add" : "Edit"}
          width="xs"
          title={`${!isEditModal ? "Add" : "Edit"} a New FAQ`}
        >
          <TextField
            autoFocus
            margin="dense"
            label="Question"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={newItem?.question}
            onChange={(e) => onChangeFAQ(e.target.value, "question")}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Answer"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={newItem?.answer}
            onChange={(e) => onChangeFAQ(e.target.value, "answer")}
            rows={5}
            multiline
          />
        </CustomDialog>

        <CustomDialog
          title="Are you sure you want to delete this question?"
          openModal={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          handleAction={handleDeleteItem}
          buttonTitle={"Yes"}
          children={undefined}
        />
      </Box>
    </>
  );
};
