export type Model = {
  ID: string;
  model_name: string;
  MSRP: number;
  dealer_price: number;
  demention_height: number | null;
  demention_width: number | null;
  demention_depth: number | null;
  demention_rack_spaces: number | null;
  images: string; // JSON string of an array of image URLs
  applicable_option_ids: string; // JSON string of an array of option IDs
  description: string;
  qty: number;
  part_number: string;
  _id?: string;
  chose_options?: any;
};

export const filteredModels = (models: Model[], idsToMatch: any) => {
  return models
    .filter((model: Model) => idsToMatch?.includes(model.ID))
    ?.map((option: Model) => option?.model_name)
    .join(", ");
};

export const generateRandomId = () => {
  return "_" + Math.random().toString(36).substr(2, 9);
};
