import { del, get, post, put, patch } from "../helpers/admin_api_helper"
import { get as getUser } from "../helpers/user_api_helper"

export const getFAQ = () => get(`/faq`)
export const getFAQByID = (data: { id?: number }) => get(`/faq/${data.id}`)
export const createFAQ = (data: {}) => post(`/faq`, data)
export const updateFAQ = (data: { id?: number }) => patch(`/faq/${data.id}`, data)
export const deleteFAQ = (data: { id?: number }) => del(`/faq/${data.id}`)
export const updateOrder = (data: {}) => post(`/faq/updateOrder`, data)

export const getUserFAQ = () => getUser(`/faq`)
