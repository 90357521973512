import { Model } from "./model";
import { Option } from "./option";

// type: dealer_price, MSRP
export const sumPrice = (
  model: Model,
  options: any[],
  type = "dealer_price"
): number => {
  if (!model || !options) return 0;
  const optionsSum = options.reduce((acc, option) => {
    let value = option?.[type];
    if (option?.QTYNumber > 1) {
      value = value * option?.QTYNumber
    }
    return acc + (typeof value === "number" ? value : 0);
  }, 0);

  const modelValue = model?.[type as keyof Model];
  return optionsSum + (typeof modelValue === "number" ? modelValue : 0);
};

export const sumTotal = (
  model: Model,
  options: Option[],
  qty: number,
  type: string
) => {
  if (!model || !options) return 0;
  return sumPrice(model, options, type) * qty;
};

export const sumDiscountPrice = (
  model: Model,
  options: Option[],
  discount: number
) => {
  if (!model || !options) return 0;
  return sumPrice(model, options) * (discount / 100);
};

export const sumDiscountTotal = (
  model: Model,
  options: Option[],
  qty: number,
  discount: number,
  type: string
) => {
  if (!model || !options) return 0;
  return sumTotal(model, options, qty, type) * (discount / 100);
};

export const subTotal = (
  data: any,
  companyInfo: any,
  type = "dealer_price"
) => {
  if (!data || !companyInfo) return 0;

  let total = 0;
  data?.forEach(({ model, options, qty }: any) => {
    if (qty >= 5 && qty <= 9) {
      return (total =
        total +
        sumTotal(model, options, qty, type) -
        sumDiscountTotal(
          model,
          options,
          qty,
          companyInfo?.qty_discount_1,
          type
        ));
    }
    if (qty > 9) {
      return (total =
        total +
        sumTotal(model, options, qty, type) -
        sumDiscountTotal(
          model,
          options,
          qty,
          companyInfo?.qty_discount_2,
          type
        ));
    } else {
      total = total + sumTotal(model, options, qty, type);
    }
  });
  return total;
};

export const getOptionData = (options: Option[], option_ids: any[]) => {
  const optionData = option_ids?.map((res: any) => {
    return options.find((option: Option) => option.ID === res.ID);
  });
  return optionData;
};

export const getQuoteNumber = (quote: string) => {
  return quote.split("-")[0];
};

export const fetchAdminEmails = (adminsData: any, emails: string[]) => {
  return emails?.map(
    (res) =>
      adminsData?.find((admin: { id: string }) => admin.id === res)?.email
  );
};

export const getOptionData1 = (options: Option[], option_ids: any[]) => {
  const optionData = option_ids?.map((res: any) => {
    const findOption: any = options.find(
      (option: Option) => option.ID === res.ID
    );
    return {
      option_name: findOption?.option_name,
      part_number: findOption?.part_number,
      MSRP: res?.MSRP,
      dealer_price: res?.dealer_price,
      QTYNumber: res?.QTYNumber || 1,
    };
  });

  return optionData;
};

export const getOptionData2 = (options: Option[], option_ids: any[]) => {
  const optionData = option_ids?.map((res: any) => {
    const findOption: any = options.find(
      (option: Option) => option.ID === res.ID
    );
    return {
      ID: res.ID,
      MSRP: findOption.MSRP,
      dealer_price: findOption.dealer_price,
      QTYNumber: res?.QTYNumber,
    };
  });

  return optionData;
};
