import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    marginRight: theme.spacing(2),
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export const DragCollapsibleList = ({ editQuestion, deleteQuestion, items, setOrder }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setOrder(reorderedItems)
  };

  if (!items.length) return null;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <StyledAccordion expanded={expanded === item.id} onChange={handleChange(item.id)}>
                      <StyledAccordionSummary
                        expandIcon={
                          <IconButton>
                            <ExpandMoreIcon />
                          </IconButton>
                        }
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <Typography variant="h6">{item.question}</Typography>
                        {editQuestion && (
                          <div>
                            <Tooltip title="Edit">
                              <IconButton onClick={(e) => editQuestion(e, item)}>
                                <EditCalendarIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton onClick={(e) => deleteQuestion(e, item)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                      </StyledAccordionSummary>
                      <AccordionDetails>
                        <List dense>
                          <ListItem>
                            <ListItemText primary="" secondary={item.answer} />
                          </ListItem>
                        </List>
                      </AccordionDetails>
                    </StyledAccordion>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};