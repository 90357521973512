import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";

import { Model } from "../../utils/model";
import { Option } from "../../utils/option";
import { Loader } from "../Loader";
import {
  sumDiscountPrice,
  sumDiscountTotal,
  sumPrice,
  sumTotal,
  subTotal,
  getOptionData1,
} from "../../utils/quote";
import { ModelOptionDataType } from "../../types/global.types";

type Props = {
  quote?: any;
  companyInfo?: any;
  options: Option[];
  models: Model[];
  priceType?: string;
};

export const PDFContent: React.FC<Props> = ({
  quote,
  companyInfo,
  models,
  options,
  priceType = "dealer_price",
}) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const modelOptionData = quote?.model_option_ids
      ? JSON.parse(quote?.model_option_ids)
      : [];

    const _modelOptionData = modelOptionData?.map(
      (res: ModelOptionDataType) => {
        let _model = models.find(
          (model: Model) => model.ID === res.model_id?.ID
        );

        return {
          model: {
            model_name: _model?.model_name,
            part_number: _model?.part_number,
            dealer_price: res.model_id?.[priceType],
            MSRP: res.model_id?.MSRP,
          },
          options: getOptionData1(options, res.option_ids),
          qty: res.qty,
        };
      }
    );

    setData(_modelOptionData);
  }, [quote, options, models, companyInfo]);

  const quoteExpirationDate = useMemo(() => {
    if (!companyInfo?.createdAt) return ""; // Return empty if no date available

    let date = new Date(quote?.createdAt); // Parse the ISO date string

    const expirationNumber =
      Number(companyInfo?.quote_expiration_setting_number) || 0; // Ensure it's a number

    if (companyInfo?.quote_expiration_setting_date === "month") {
      date.setMonth(date.getMonth() + expirationNumber);
    } else if (companyInfo?.quote_expiration_setting_date === "year") {
      date.setFullYear(date.getFullYear() + expirationNumber);
    } else {
      date.setDate(date.getDate() + expirationNumber); // Fix: Use getDate() instead of getDay()
    }
    return moment(date).format("MM/DD/YYYY");
  }, [companyInfo, quote]);

  if (!data) return <Loader isLoading={!data} />;

  return (
    <div
      style={{
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 12,
        // padding: ".4in .3in",
      }}
    >
      <div
        style={{
          background: "#000",
          color: "#FFF",
          paddingLeft: "0.3in",
          position: "relative",
        }}
      >
        <h3
          style={{
            float: "left",
            width: "50%",
            boxSizing: "border-box",
            lineHeight: "35px",
          }}
        >
          {priceType === "dealer_price" ? "Dealer Quote # " : "MSRP Quote # "}
          {quote?.no}
        </h3>
        <img
          src={
            typeof companyInfo?.company_logo === "string"
              ? `${process.env.REACT_APP_COMPANY_LOGO_URL}/${companyInfo?.company_logo}`
              : undefined
          }
          style={{
            display: "inline-block",
            width: "auto",
            height: "100px",
            position: "absolute",
            left: "calc(50% - 55px)",
            objectFit: "cover",
          }}
        />
        <h4
          style={{
            float: "left",
            width: "50%",
            boxSizing: "border-box",
            textAlign: "center",
            lineHeight: "36px",
          }}
        >
          Generated: {moment(quote.createdAt).format("MM/DD/YYYY")}
        </h4>
        <div style={{ clear: "both" }}></div>
      </div>
      <div style={{ padding: "0 .3in" }}>
        <div className="customer-info" style={{ margin: "15px 0" }}>
          <div style={{ margin: "5px 0" }}>
            {companyInfo?.dealer_name || companyInfo?.company_name}
          </div>
          <div style={{ margin: "5px 0" }}>{companyInfo?.contact_name}</div>
          <div style={{ margin: "5px 0" }}>{companyInfo?.address}</div>
          <div style={{ margin: "5px 0" }}>
            {companyInfo?.city}, {companyInfo?.state}, {companyInfo?.zip}
          </div>
          <div style={{ margin: "5px 0" }}>{companyInfo?.country}</div>
        </div>
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            textAlign: "center",
            fontFamily: "Arial, Helvetica, sans-serif",
            fontSize: 10,
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "black",
                  color: "white",
                }}
              >
                Product
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "black",
                  color: "white",
                }}
              >
                Item No.
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "black",
                  color: "white",
                }}
              >
                Qty
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "black",
                  color: "white",
                }}
              >
                {priceType === "MSRP" ? priceType : "Price"}
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "black",
                  color: "white",
                }}
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map(({ model, options, qty }: any, index: number) => (
              <>
                <tr>
                  <td>Model - {model?.model_name}</td>
                  <td>{model?.part_number}</td>
                  <td>{qty}</td>
                  <td>{model?.[priceType]?.toFixed(2)}</td>
                  <td>${(model?.[priceType] * qty)?.toFixed(2)}</td>
                </tr>
                {options &&
                  options.length > 0 &&
                  options.map((res: any) =>
                    Array.from({ length: res?.QTYNumber }, (_, index) => (
                      <tr key={`${res?.part_number}-${index}`}>
                        <td>Opt. {res?.option_name} #{index + 1}</td>
                        <td>{res?.part_number}</td>
                        <td>{qty}</td>
                        <td>{res?.[priceType]?.toFixed(2)}</td>
                        <td>${(res?.[priceType] * qty)?.toFixed(2)}</td>
                      </tr>
                    ))
                  )}
                {qty >= 5 && qty <= 9 && (
                  <tr>
                    <td>Volume Discount 5-9 Unit</td>
                    <td>{`${companyInfo?.qty_discount_1}%`}</td>
                    <td>{qty}</td>
                    <td>
                      -
                      {sumDiscountPrice(
                        model,
                        options,
                        companyInfo?.qty_discount_1
                      ).toFixed(2)}
                    </td>
                    <td>
                      $-
                      {sumDiscountTotal(
                        model,
                        options,
                        qty,
                        companyInfo?.qty_discount_1,
                        priceType
                      ).toFixed(2)}
                    </td>
                  </tr>
                )}
                {qty > 9 && (
                  <tr>
                    <td>Volume Discount 10+ Units</td>
                    <td>{`${companyInfo?.qty_discount_2}%`}</td>
                    <td>{qty}</td>
                    <td>
                      -
                      {sumDiscountPrice(
                        model,
                        options,
                        companyInfo?.qty_discount_2
                      ).toFixed(2)}
                    </td>
                    <td>
                      $-
                      {sumDiscountTotal(
                        model,
                        options,
                        qty,
                        companyInfo?.qty_discount_2,
                        priceType
                      ).toFixed(2)}
                    </td>
                  </tr>
                )}
                <tr className="system">
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 5,
                      background: "rgb(135 135 135)",
                      color: "white",
                    }}
                  >
                    System {index + 1}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 5,
                      background: "rgb(135 135 135)",
                      color: "white",
                    }}
                  />
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 5,
                      background: "rgb(135 135 135)",
                      color: "white",
                    }}
                  >
                    {qty}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 5,
                      background: "rgb(135 135 135)",
                      color: "white",
                    }}
                  >
                    $
                    {qty >= 5 &&
                      qty <= 9 &&
                      (
                        sumPrice(model, options, priceType) -
                        sumDiscountPrice(
                          model,
                          options,
                          companyInfo?.qty_discount_1
                        )
                      )?.toFixed(2)}
                    {qty > 9 &&
                      (
                        sumPrice(model, options, priceType) -
                        sumDiscountPrice(
                          model,
                          options,
                          companyInfo?.qty_discount_2
                        )
                      )?.toFixed(2)}
                    {qty < 5 && sumPrice(model, options, priceType)?.toFixed(2)}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 5,
                      background: "rgb(135 135 135)",
                      color: "white",
                    }}
                  >
                    $
                    {qty >= 5 &&
                      qty <= 9 &&
                      (
                        sumTotal(model, options, qty, priceType) -
                        sumDiscountTotal(
                          model,
                          options,
                          qty,
                          companyInfo?.qty_discount_1,
                          priceType
                        )
                      )?.toFixed(2)}
                    {qty > 9 &&
                      (
                        sumTotal(model, options, qty, priceType) -
                        sumDiscountTotal(
                          model,
                          options,
                          qty,
                          companyInfo?.qty_discount_2,
                          priceType
                        )
                      )?.toFixed(2)}
                    {qty < 5 &&
                      sumTotal(model, options, qty, priceType)?.toFixed(2)}
                  </td>
                </tr>
              </>
            ))}
            <tr>
              <td colSpan={3}></td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "rgb(135 135 135)",
                  color: "white",
                }}
              >
                Subtotal
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "rgb(135 135 135)",
                  color: "white",
                }}
              >
                ${subTotal(data, companyInfo, priceType)?.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colSpan={3}></td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "rgb(135 135 135)",
                  color: "white",
                }}
              >
                Tax
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "rgb(135 135 135)",
                  color: "white",
                }}
              >
                $0
              </td>
            </tr>
            <tr>
              <td colSpan={3}></td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "#000000",
                  color: "white",
                }}
              >
                Total
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 5,
                  background: "#000000",
                  color: "white",
                }}
              >
                ${subTotal(data, companyInfo, priceType)?.toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="notes">
          <p>
            Your quote is locked in for{" "}
            {companyInfo?.quote_expiration_setting_number}{" "}
            {companyInfo?.quote_expiration_setting_date}s.
          </p>
          <p>
            Prices are subject to change <br />
            after {quoteExpirationDate} in accordance <br />
            with part and labor price fluctuations.
          </p>
        </div>
        <div
          className="footer"
          id="pdfFooter"
          style={{
            textAlign: "center",
            // position: "absolute",
            // bottom: 0,
            // left: 0,
            // right: 0,
          }}
        >
          <p>
            Bigfoot Mobile Systems - BigFootMobileSystems.com - 707.602.5548
          </p>
        </div>
      </div>
    </div>
  );
};
