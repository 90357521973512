import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

import { createUserQuote } from "../../../apis/quote";
import { Carousel } from "../../../components/Carousel";
import { CustomDialog } from "../../../components/modal/CustomDialog";
import { useOptionData } from "../../../hook/useOptionData";
import { Model } from "../../../utils/model";
import {
  transformOriginalOptions,
  transformOptions,
  Option,
} from "../../../utils/option";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { RootState } from "../../../store/store";
import { setQuoteModels } from "../../../store/actions/userAuthActions";
import { Loader } from "../../../components/Loader";
import { fetchAdminEmails, getOptionData, getOptionData2, subTotal } from "../../../utils/quote";
import { useCompanyData } from "../../../hook/useCompanyData";
import { getAll, sendQuote } from "../../../apis/admin";

const useStyles = makeStyles(() => ({
  firstContent: {
    textAlign: "center",
  },
  CardContent: {
    width: "25%",
  },
  root: {
    display: "flex",
    gap: "10px",
    position: "relative",
  },
  btnGroup: {
    width: "10%",
    display: "flex",
    alignItems: "start",
    justifyContent: "end",
  },
}));

export const UserQuoteBuilder: React.FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const quoteModels = useSelector(
    (state: RootState) => state.userAuth.quoteModels
  );

  const { company } = useCompanyData("dealer");

  const currentUser = useSelector((state: RootState) => state.userAuth.user);

  const dispatch = useDispatch();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openBuildModal, setOpenBuildModal] = useState<boolean>(false);

  const [activeQuoteModel, setActiveQuoteModel] = useState<any>({});
  const [orderedOptions, setOrderedOptions] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ccEmails, setCCEmails] = useState<string[]>([]);

  const { showSnackBar } = useGlobalSnackBar();

  const { options } = useOptionData("dealer");

  const fetchAdmins = async () => {
    try {
      const adminsData = await getAll();
      setCCEmails(
        fetchAdminEmails(adminsData, JSON.parse(company.to_copy_admins))
      );
    } catch (error) {
      console.error("Failed to fetch company", error);
    }
  };

  useEffect(() => {
    if (!company) return;
    fetchAdmins();
  }, [company]);

  const makeOrderedOptionData = useCallback(() => {
    const orderedOptionData = transformOriginalOptions(
      transformOptions(options, true),
      true
    );
    const _orderedOptionData = orderedOptionData?.filter(
      (option: Option) => !option.is_category
    );
    setOrderedOptions(_orderedOptionData);
    setIsLoading(false);
  }, [options]);

  useEffect(() => {
    makeOrderedOptionData();
  }, [options]);

  useEffect(() => {
    const _orderedOptions = orderedOptions?.map((option: Option) =>
      activeQuoteModel?.chose_options?.find((id: string) => id === option.ID)
        ? { ...option, isAdded: true }
        : { ...option, isAdded: false }
    );
    setOrderedOptions(_orderedOptions);
  }, [activeQuoteModel]);

  const handleSubtract = (ID: string) => {
    const _quoteModel = quoteModels.map((item: Model) =>
      item._id === ID ? { ...item, qty: item.qty ? item.qty - 1 : 1 } : item
    );
    dispatch(setQuoteModels(_quoteModel));
  };

  const handleAdd = (ID: string) => {
    const _quoteModel = quoteModels.map((item: Model) =>
      item._id === ID ? { ...item, qty: item.qty ? item.qty + 1 : 2 } : item
    );
    dispatch(setQuoteModels(_quoteModel));
  };

  const onDeleteModel = () => {
    const _quoteModels = quoteModels.filter(
      (model: { _id: string }) => model._id !== activeQuoteModel._id
    );
    dispatch(setQuoteModels(_quoteModels));
    setOpenDeleteModal(false);
  };

  const getChoseOptions = useCallback(
    (choseOptions: any) => {
      const optionNames = choseOptions?.map((choseOption: any) => {
        const matchedOption = options.find((option: any) => option.ID === choseOption.ID);
        return matchedOption
          ? (matchedOption.isQTYEnabled && choseOption?.QTYNumber > 1)
            ? `${matchedOption.option_name}(qty: ${choseOption?.QTYNumber})`
            : matchedOption.option_name
          : null;
      });

      return optionNames?.filter(Boolean).join(", ");
    },
    [options]
  );

  const onBuildModal = async () => {
    const data = quoteModels.map((model: any) => ({
      model_id: {
        ID: model.ID,
        MSRP: model.MSRP,
        dealer_price: model.dealer_price,
      },
      option_ids: model.chose_options ? getOptionData2(options, model.chose_options) : [],
      qty: model.qty || 1,
    }));

    try {
      const res = await createUserQuote({
        model_option_ids: JSON.stringify(data),
        dealer_id: currentUser.id,
      });

      try {
        await sendQuote({
          ccEmails: ccEmails,
          ID: res?.ID,
        });

        showSnackBar("Build successfully.");
        dispatch(setQuoteModels([]));
        navigate(`/_quote/${res.ID}`);
      } catch (err) {
        console.error(err);
      }

    } catch (err) {
      console.error(err);
    }
  };

  const getTotalDealerPrice = useMemo(() => {
    const transformedArray = quoteModels?.map((item: Model) => ({
      model: { ...item },
      options: getOptionData(options, item.chose_options),
      qty: item?.qty || 1,
    }));
    return subTotal(transformedArray, company).toFixed(2);
  }, [options, quoteModels, company]);

  const getTotalMSRPPrice = useMemo(() => {
    const transformedArray = quoteModels?.map((item: Model) => ({
      model: { ...item },
      options: getOptionData(options, item.chose_options),
      qty: item?.qty || 1,
    }));
    return subTotal(transformedArray, company, "MSRP").toFixed(2);
  }, [options, quoteModels, company]);

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography align="right" mt={1.5} mb={1.5}>
        <Button variant="contained" onClick={() => navigate("/_models")}>
          Add Model
        </Button>
      </Typography>

      <Grid container sx={{ pr: { md: "15%" }, pl: { md: "15%" } }}>
        {quoteModels?.map((model: any) => (
          <Grid item xs={12} sm={12} md={12} key={model._id} sx={{ mb: 3 }}>
            <Card className={classes.root}>
              <CardContent
                className={`${classes.firstContent} ${classes.CardContent}`}
              >
                <Typography gutterBottom variant="h5" component="div">
                  QTY
                </Typography>
                {/* <Input type="number" width={100} /> */}
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                  sx={{ mb: 1 }}
                >
                  <Button
                    onClick={() => handleSubtract(model._id)}
                    sx={{ color: "black", backgroundColor: "white" }}
                  >
                    -
                  </Button>
                  <Button sx={{ color: "black", backgroundColor: "white" }}>
                    {model.qty || 1}
                  </Button>
                  <Button
                    onClick={() => handleAdd(model._id)}
                    sx={{ color: "black", backgroundColor: "white" }}
                  >
                    +
                  </Button>
                </ButtonGroup>
                <Typography variant="body2" color="text.secondary">
                  Quantity discounts start at 5 duplicate units and further
                  increase at 10+ units.
                </Typography>
              </CardContent>

              <Carousel
                images={
                  model.images
                    ? JSON.parse(model.images)?.filter(
                      (item: any) => item !== null
                    )
                    : []
                }
              />

              <CardContent className={classes.CardContent}>
                <Typography gutterBottom variant="h5" component="div">
                  {model.model_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Part #: {model.part_number}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  MSRP: {model.MSRP}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Dealer Price: {model.dealer_price}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Chose Options:{" "}
                  {getChoseOptions(model?.chose_options) || <i>Empty</i>}
                </Typography>
              </CardContent>

              <CardContent className={classes.btnGroup}>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() =>
                      navigate(`/_models/${model.ID}*${model._id}/customize`)
                    }
                  >
                    <EditCalendarIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => {
                      setActiveQuoteModel(model);
                      setOpenDeleteModal(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {quoteModels && quoteModels.length > 0 && (
        <Grid container sx={{ pr: { md: "15%" }, pl: { md: "15%" } }}>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            sx={{ width: "100%" }}
          >
            Running Totals
          </Typography>
          <Typography gutterBottom component="div" sx={{ width: "100%" }}>
            MSRP: ${getTotalMSRPPrice}
          </Typography>
          <Typography gutterBottom component="div" sx={{ width: "100%" }}>
            {/* DEALER: ${totalPrice(quoteModels, "dealer_price")} */}
            DEALER: ${getTotalDealerPrice}
          </Typography>

          <Typography align="center" mt={4.5} mb={1.5} sx={{ width: "100%" }}>
            <p>Totals do not reflect sales tax where applicable.</p>
            <Button variant="contained" onClick={() => setOpenBuildModal(true)}>
              Finalize Quote
            </Button>
          </Typography>
        </Grid>
      )}

      <CustomDialog
        title="Are you sure you want to delete this model?"
        openModal={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleAction={onDeleteModel}
        buttonTitle={"Yes"}
        children={undefined}
      />

      <CustomDialog
        title="Are you sure you want to build this quote?"
        openModal={openBuildModal}
        handleClose={() => setOpenBuildModal(false)}
        handleAction={onBuildModal}
        buttonTitle={"Yes"}
        children={undefined}
      />
    </Box>
  );
};
