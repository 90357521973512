import React from "react";
import { 
    Typography,
} from "@mui/material";

import "./styles.css";


export const DimensionsContext: React.FC<any> = ({
    option
}) => {

    return (
        <>
            {option?.isDimensionsEnabled && (
                <>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                        Dimensions
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Height: {option.demention_height}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Width: {option.demention_width}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Depth: {option.demention_depth}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Rack Spaces: {option.demention_rack_spaces}
                    </Typography>
                </>
            )}
        </>
    );
};
