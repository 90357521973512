import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  FormHelperText,
  TabsProps,
  Typography,
} from "@mui/material";
import axios from "axios";

import { Input } from "../../../components/Input";
import { SelectComponent } from "../../../components/Select";
import { phoneRegex, usStates, zipRegex } from "../../../constants/Constant";
import {
  createCompany,
  getCompany,
  updateCompany,
} from "../../../apis/company";
import { CompanyProps } from "../../../constants/Interface";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const InfoTab: React.FC<TabsProps> = ({ value }) => {
  const [company, setCompany] = useState<CompanyProps | null>(null);
  const [logo, setLogo] = useState<string | ArrayBuffer | null>(null);
  const [phone, setPhone] = useState<string>();
  const [zip, setZip] = useState<string>();
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);
  const [isZipValid, setIsZipValid] = useState<boolean>(true);
  const currentUser = useSelector((state: RootState) => state.adminAuth.admin);

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setPhone(newValue);
    setIsPhoneValid(phoneRegex.test(newValue));
  };

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setZip(newValue);
    setIsZipValid(zipRegex.test(newValue));
  };

  const { showSnackBar } = useGlobalSnackBar();

  const fetchAdmins = async () => {
    try {
      const companyData = await getCompany();
      setCompany(companyData?.[0]);
      setLogo(companyData?.[0].company_logo);
    } catch (error) {
      console.error("Failed to fetch company", error);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  const permissionErrorMessage = () => {
    showSnackBar("Invalid operation: Only Administrators can make this change.", "error");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (currentUser?.permission !== "Administrator") {
      permissionErrorMessage();
      return;
    }

    const formData = new FormData(event.target);
    const formValues: any = Object.fromEntries(formData.entries());
    formValues["company_logo"] = logo;

    if (!isPhoneValid || !isZipValid) {
      showSnackBar("Form is invalid! Please check the fields...", "error");
      return;
    }

    if (!company?.ID) {
      const res = await createCompany(formValues);
      showSnackBar("Created!");
    } else {
      let reFormValues = { ...formValues, ID: company.ID };
      const res = await updateCompany(reFormValues);
      showSnackBar("Updated!");
    }
    fetchAdmins();
  };

  const handleUploadClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("img", file);

    axios
      .post(process.env.REACT_APP_UPLOAD_URL || "", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("admin_access_token"),
        },
      })
      .then((response) => {
        const { filename } = response.data;
        setLogo(filename);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  return (
    <CustomTabPanel value={value} index={2}>
      <Box sx={{ flexGrow: 1 }} component="form" onSubmit={handleSubmit}>
        <Typography variant="h5" align="center" mt={2.5} mb={1.5}>
          Company Info
        </Typography>
        <Typography
          variant="h6"
          align="center"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2.5,
            mb: 1.5,
            gap: 1,
            justifyContent: "center",
          }}
        >
          <Typography sx={{ width: "20%" }} align="center">
            <Avatar
              src={
                typeof logo === "string"
                  ? `${process.env.REACT_APP_COMPANY_LOGO_URL}/${logo}`
                  : undefined
              }
              sx={{
                display: "block",
                width: "200px",
                height: "200px",
                m: "0 auto",
              }}
            />
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={(e) => handleUploadClick(e)}
              style={{ display: "none" }}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                sx={{ mt: 3 }}
                disabled={currentUser?.permission !== "Administrator"}
              >
                {logo ? "Change" : "Upload Logo"}
              </Button>
            </label>
          </Typography>
          <Typography
            align="center"
            sx={{
              width: "40%",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2.5,
                mb: 1.5,
                gap: 1,
                justifyContent: "center",
              }}
            >
              <Typography sx={{ width: "20%" }} align="right">
                Name:
              </Typography>
              <Input defaultValue={company?.company_name} name="company_name" />
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2.5,
                mb: 1.5,
                gap: 1,
                justifyContent: "center",
              }}
            >
              <Typography sx={{ width: "20%" }} align="right">
                Contact:
              </Typography>
              <Input defaultValue={company?.contact_name} name="contact_name" />
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2.5,
                mb: 1.5,
                gap: 1,
                justifyContent: "center",
              }}
            >
              <Typography sx={{ width: "20%" }} align="right">
                Phone:
              </Typography>
              <Input
                defaultValue={company?.phone}
                value={phone}
                name="phone"
                onChange={handlePhoneChange}
                error={!isPhoneValid}
              />
            </Typography>

            {!isPhoneValid && (
              <FormHelperText error sx={{ textAlign: "center" }}>
                Please enter a valid phone number in the format +1 (707)
                123-4567
              </FormHelperText>
            )}
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2.5,
                mb: 1.5,
                gap: 1,
                justifyContent: "center",
              }}
            >
              <Typography sx={{ width: "20%" }} align="right">
                Country:
              </Typography>
              <Input defaultValue={company?.country} name="country" />
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2.5,
                mb: 1.5,
                gap: 1,
                justifyContent: "center",
              }}
            >
              <Typography sx={{ width: "20%" }} align="right">
                Address:
              </Typography>
              <Input defaultValue={company?.address} name="address" />
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2.5,
                mb: 1.5,
                gap: 1,
                justifyContent: "center",
              }}
            >
              <Typography sx={{ width: "20%" }} align="right">
                City:
              </Typography>
              <Input defaultValue={company?.city} name="city" />
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2.5,
                mb: 1.5,
                gap: 1,
                justifyContent: "center",
              }}
            >
              <Typography sx={{ width: "20%" }} align="right">
                State:
              </Typography>
              <SelectComponent
                defaultValue={company?.state}
                name="state"
                menuItems={usStates}
              />
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2.5,
                mb: 1.5,
                gap: 1,
                justifyContent: "center",
              }}
            >
              <Typography sx={{ width: "20%" }} align="right">
                Zip:
              </Typography>
              <Input
                defaultValue={company?.zip}
                name="zip"
                value={zip}
                onChange={handleZipChange}
                error={!isZipValid}
              />
            </Typography>

            {!isZipValid && (
              <FormHelperText error sx={{ textAlign: "center" }}>
                Please enter a valid zip in the format xxxxx-xxxx
              </FormHelperText>
            )}
          </Typography>
        </Typography>
        <Typography align="center" mt={5.5} mb={1.5}>
          <Button sx={{ width: "150px" }} variant="contained" type="submit">
            Save
          </Button>
        </Typography>
      </Box>
    </CustomTabPanel>
  );
};
