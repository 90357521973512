import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";

import { DraggableTreeView } from "../../../components/DraggableTreeView";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { useOptionData } from "../../../hook/useOptionData";
import {
  createOption,
  deleteOption,
  updateOption,
  updateOptions,
} from "../../../apis/option";
import {
  Option,
  transformOptions,
  transformOriginalOptions,
} from "../../../utils/option";
import { CustomDialog } from "../../../components/modal/CustomDialog";
import { Loader } from "../../../components/Loader";

import "./styles.css";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";

interface StatCardProps {
  value: number | string;
  description: string;
}

const StatCard: React.FC<StatCardProps> = ({ value, description }) => (
  <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
    <Card sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}>
      <CardContent sx={{ pb: "10px !important" }}>
        <Typography variant="h5" component="div">
          {value}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

interface TreeViewDataItem {
  text: any;
  id: string;
  expanded?: boolean;
  checked?: boolean;
  selected?: boolean;
  items?: TreeViewDataItem[];
}

function countNestedSubcategories(arrays: TreeViewDataItem[]) {
  // Helper function to count nested items recursively
  function countItems(items: any) {
    let count = 0;
    for (const item of items) {
      if (item.items && item.items.length > 0) {
        count += 1; // Count the current non-empty items array
        count += countItems(item.items); // Recursively count items within the nested items
      }
    }
    return count;
  }

  let totalCount = 0;
  for (const rootItem of arrays) {
    if (rootItem.items && rootItem.items.length > 0) {
      totalCount += countItems(rootItem.items); // Start counting from the second level
    }
  }
  return totalCount;
}

const extractText = (data: any) => {
  return data.map((item: any) => ({
    text: item.text.props.children[0].props.children,
    // text: item.text.props.children.props.children,
    id: item.id,
    items: item.items ? extractText(item.items) : [],
  }));
};

const removeItemById = (
  tree: TreeViewDataItem[],
  id: string
): TreeViewDataItem[] => {
  return tree
    .filter((item) => item.id !== id) // Exclude the item with the specified id
    .map((item) => ({
      ...item,
      items: item.items ? removeItemById(item.items, id) : undefined, // Recurse into nested items
    }));
};

export const Categories = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [newItem, setNewItem] = useState<string | "">("");
  const [editCategoryId, setEditCategoryId] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const { showSnackBar } = useGlobalSnackBar();
  const currentUser = useSelector((state: RootState) => state.adminAuth.admin);

  const { options } = useOptionData();

  const [tree, setTree] = useState<TreeViewDataItem[]>([]);

  const itemRow = (text: string, id: string, is_category: boolean) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography variant="body1">{text}</Typography>
        {is_category && (
          <div>
            <Tooltip title="Edit">
              <IconButton onClick={(e) => editQuestion(e, text, id)}>
                <EditCalendarIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={(e) => deleteQuestion(e, text, id)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  const transformData = (data: any) => {
    return data.map((item: any) => ({
      text: itemRow(item.text, item.id, item.is_category),
      id: item.id,
      items: item.items ? transformData(item.items) : [],
      is_category: item.is_category,
    }));
  };

  useEffect(() => {
    if (options) {
      setTree(transformData(transformOptions(options, true)));
      setLoading(false);
    }
  }, [options]);

  const permissionErrorMessage = () => {
    showSnackBar("Invalid operation: Only Administrators can make this change.", "error");
  };

  const onSave = async () => {
    if (currentUser?.permission !== "Administrator") {
      permissionErrorMessage();
      return;
    }
    const originalData = extractText(tree);

    try {
      await updateOptions({
        data: transformOriginalOptions(originalData),
      });
      showSnackBar("Your changes have been saved successfully.");
    } catch (err) {
      showSnackBar(
        "There was an issue saving your changes. Please try again later.",
        "error"
      );
    }
  };

  const updateTextById = (
    tree: TreeViewDataItem[],
    id: string,
    newText: string
  ) => {
    for (const item of tree) {
      if (item.id === id) {
        item.text = itemRow(newText, id, true);
        return;
      }
      if (item.items) {
        updateTextById(item.items, id, newText);
      }
    }
  };

  const handleAddItem = async () => {
    if (currentUser?.permission !== "Administrator") {
      permissionErrorMessage();
      return;
    }
    if (!isEditModal) {
      // create new category
      try {
        const res = await createOption({
          option_name: newItem,
          is_category: true,
        });
        setTree((prevItems) => [
          ...prevItems,
          {
            text: itemRow(newItem, res.ID, res.is_category),
            id: res.ID,
            is_category: res.is_category,
          },
        ]);
        showSnackBar("Added new category successfully.");
      } catch (err) {
        console.error(err);
      }
    } else {
      // edit category
      if (!editCategoryId) return;
      try {
        await updateOption({ ID: editCategoryId, option_name: newItem });
        updateTextById(tree, editCategoryId, newItem);
      } catch (err) {
        console.error(err);
      }
    }
    setNewItem("");
    setOpenModal(false);
    setIsEditModal(false);
  };

  const editQuestion = (e: any, item: any, id: string) => {
    // e.preventDefault();
    // e.stopPropagation();
    setNewItem(item);
    setOpenModal(true);
    setIsEditModal(true);
    setEditCategoryId(id);
    // setEditItemIndex(index);
  };

  const deleteQuestion = (e: any, item: any, id: string) => {
    // e.preventDefault();
    // e.stopPropagation();
    // setEditItemIndex(index);
    setOpenDeleteModal(true);
    setEditCategoryId(id);
  };

  const handleDeleteItem = async () => {
    if (currentUser?.permission !== "Administrator") {
      permissionErrorMessage();
      return;
    }
    if (!editCategoryId) return;

    try {
      await deleteOption({ ID: editCategoryId });
      const updatedTreeData = removeItemById(tree, editCategoryId);
      setTree(updatedTreeData);
      setOpenDeleteModal(false);
      showSnackBar("Removed this category.");
    } catch (err) {
      console.error(err);
    }
  };

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <StatCard value={tree?.length} description="Main Categories" />
          <StatCard
            value={countNestedSubcategories(tree)}
            description="Sub-Categories"
          />
          <StatCard
            value={options?.filter((res: Option) => !res.is_category).length}
            description="Options"
          />
          <Grid item xs={3}>
            <Card
              sx={{
                width: "60%",
                minWidth: "180px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setOpenModal(true);
                setIsEditModal(false);
              }}
            >
              <CardContent sx={{ pb: "10px !important" }}>
                <Typography variant="h5" component="div">
                  +
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Category
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={5}>
          <Grid item xs={12}>
            <DraggableTreeView tree={tree} setTree={setTree} />
          </Grid>
        </Grid>
        <Typography align="center" mt={5.5} mb={1.5}>
          <Button sx={{ width: "150px" }} variant="contained" onClick={onSave}>
            Save
          </Button>
        </Typography>
      </Box>

      <CustomDialog
        openModal={openModal}
        handleClose={() => setOpenModal(false)}
        handleAction={handleAddItem}
        buttonTitle={!isEditModal ? "Add" : "Edit"}
        width="xs"
        title={`${!isEditModal ? "Add" : "Edit"} a Category`}
      >
        <TextField
          autoFocus
          margin="dense"
          label={`${!isEditModal ? "New" : "Edit"} Category`}
          type="text"
          fullWidth
          variant="standard"
          value={newItem}
          onChange={(e) => setNewItem(e.target.value)}
        />
      </CustomDialog>

      <CustomDialog
        title="Are you sure you want to delete this category? You must move options to other category."
        openModal={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleAction={handleDeleteItem}
        buttonTitle={"Yes"}
        children={undefined}
      />
    </>
  );
};
