import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  ListItem,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import DefaultTable from "../../../components/Table/DefaultTable";
import { RootState } from "../../../store/store";
import { getUserDealerByID } from "../../../apis/dealer";
import { Loader } from "../../../components/Loader";
import { getQuoteByDealerID } from "../../../apis/quote";
import { useModelData } from "../../../hook/useModelData";
import { ModelOptionDataType } from "../../../types/global.types";
import { Model } from "../../../utils/model";
import { getOptionData1, subTotal } from "../../../utils/quote";
import { useOptionData } from "../../../hook/useOptionData";
import { useCompanyData } from "../../../hook/useCompanyData";

interface StatCardProps {
  value: number | string;
  description: string;
}

const StatCard: React.FC<StatCardProps> = ({ value, description }) => (
  <Grid
    item
    xs={3}
    sx={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
  >
    <Card sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}>
      <CardContent sx={{ pb: "10px !important" }}>
        <Typography variant="h5" component="div">
          {value}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

const headerData: string[] = ["Dealer", "Contact", "Quote #", "Date", "Status", "Dealer Price", "MSRP"];

export const UserDashboard: React.FC = () => {
  const history = useNavigate();
  const currentUser = useSelector((state: RootState) => state.userAuth.user);

  const { models } = useModelData("dealer");

  const { company } = useCompanyData("dealer");
  const { options } = useOptionData("dealer");

  const [dealer, setDealer] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [quotes, setQuotes] = useState([]);
  const [data, setData] = useState<any>();

  useEffect(() => {
    const fetchDealer = async () => {
      try {
        const dealerData = await getUserDealerByID({ ID: currentUser.id });
        setDealer({ ...dealerData });
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch dealer", error);
      }
    };

    if (currentUser?.id) {
      fetchDealer();
    } else {
      setIsLoading(false);
    }
  }, [currentUser]);

  const priceCalculation = (data: any, type: string) => {
    const modelOptionData = data?.model_option_ids
      ? JSON.parse(data?.model_option_ids)
      : [];

    return modelOptionData?.map(
      (res: ModelOptionDataType) => {
        let _model = models.find(
          (model: Model) => model.ID === res.model_id?.ID
        );

        return {
          model: {
            model_name: _model?.model_name,
            part_number: _model?.part_number,
            dealer_price: res.model_id?.[type],
            MSRP: res.model_id?.MSRP,
          },
          options: getOptionData1(options, res.option_ids),
          qty: res.qty,
        };
      }
    )
  }

  const fetchQuotes = async () => {
    try {
      const { data: quoteData, counts, allData } = await getQuoteByDealerID({
        dealer_id: currentUser?.id,
        page: page + 1,
        pageSize,
      });

      const transformedData = quoteData?.map((quote: any) => ({
        dealer: quote?.dealer_name,
        contact: quote?.contact_name,
        quotes: (
          <ListItem
            disablePadding
            component={Link}
            to={`/_quote/${quote.quoteId}`}
          >
            {quote.no}
          </ListItem>
        ),
        last_activity: moment(quote.createdAt).format("MM/DD/YYYY"),
        status: quote.quote_status,
        dealer_price: `$${subTotal(priceCalculation(quote, 'dealer_price'), company, 'dealer_price')?.toFixed(2)}`,
        msrp: `$${subTotal(priceCalculation(quote, 'MSRP'), company, 'MSRP')?.toFixed(2)}`,
      }));

      setData(allData)
      setQuotes(transformedData);
      setCount(counts);

      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch admins", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (!currentUser) return;

    fetchQuotes();
  }, [currentUser, page, pageSize, company]);

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setPageSize(Number(e.target.value));
  };

  const priceValue = (index: number, type: string) => {
    const modelOptionData = data?.[index]?.model_option_ids
      ? JSON.parse(data?.[index]?.model_option_ids)
      : [];


    return modelOptionData?.map(
      (res: ModelOptionDataType) => {
        let _model = models.find(
          (model: Model) => model.ID === res.model_id?.ID
        );

        return {
          model: {
            model_name: _model?.model_name,
            part_number: _model?.part_number,
            dealer_price: res.model_id?.[type],
            MSRP: res.model_id?.MSRP,
          },
          options: getOptionData1(options, res.option_ids),
          qty: res.qty,
        };
      }
    );
  }

  const quoteExpirationDate = (quote: any) => {
    if (!company?.createdAt) return ""; // Return empty if no date available

    let date = new Date(quote?.createdAt); // Parse the ISO date string

    const expirationNumber =
      Number(company?.quote_expiration_setting_number) || 0; // Ensure it's a number

    if (company?.quote_expiration_setting_date === "month") {
      date.setMonth(date.getMonth() + expirationNumber);
    } else if (company?.quote_expiration_setting_date === "year") {
      date.setFullYear(date.getFullYear() + expirationNumber);
    } else {
      date.setDate(date.getDate() + expirationNumber); // Fix: Use getDate() instead of getDay()
    }
    return moment(date).format("MM/DD/YYYY");
  };

  const handleDownloadCSV = () => {
    const headers = headerData.join(",") + ", Expiration Date" + "\n";

    const csvRows = data?.map((quote: any, index: number) =>
      `${quote.dealer_id === "ADMIN" ? company?.company_name : quote.dealer_name},${quote.dealer_id === "ADMIN" ? company?.contact_name : quote?.contact_name},${quote.no},${moment(quote.createdAt).format("MM/DD/YYYY")},${quote.quote_status},${subTotal(priceValue(index, 'dealer_price'), company, 'dealer_price')?.toFixed(2)},${subTotal(priceValue(index, 'MSRP'), company, 'MSRP')?.toFixed(2)},${quoteExpirationDate(quote)}`
    );

    console.log(data)
    const csvString = headers + csvRows.join("\n");

    // Create Blob and trigger download
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "table.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h3" align="center" mt={2.5} mb={2.5}>
          Welcome, {dealer?.dealer_name}
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: "space-between" }}
          onClick={() => history("/_models")}
        >
          {/* <StatCard value={35} description="Previous Quotes" /> */}
          <StatCard value={models?.length} description="Browser By Model" />
          {/* <StatCard value={25} description="Browse By Option" /> */}
          <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => history("/_models")}
              sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}
            >
              <CardContent sx={{ pb: "10px !important" }}>
                <Typography variant="h5" component="div">
                  +
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  New Quote
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6" align="center" mt={2.5} mb={1.5}>
          Activity
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadCSV}
          sx={{ margin: 2 }}
        >
          Download CSV
        </Button>
        <DefaultTable
          headerData={headerData}
          rowsData={quotes}
          rowsPerPage={pageSize}
          page={page}
          count={count}
          handleChangePage={(
            e: React.ChangeEvent<HTMLInputElement>,
            page: number
          ) => handleChangePage(page)}
          handleChangeRowsPerPage={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeRowsPerPage(e)
          }
        />
      </Box>
    </>
  );
};
