import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";
import { FAQDataType } from "../../types/global.types";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    marginRight: theme.spacing(2), // Adjust this value to control the spacing
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1), // Ensure content shifts right to accommodate icon
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

type Props = {
  editQuestion?: any;
  deleteQuestion?: any;
  items: FAQDataType[];
};
export const CollapsibleList: React.FC<Props> = ({
  editQuestion,
  deleteQuestion,
  items,
}): any => {
  const [expanded, setExpanded] = React.useState<number | false>(false);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  if (!items.length) return;

  return (
    <div>
      {items.map((item, index) => (
        <StyledAccordion
          key={item.id}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <StyledAccordionSummary
            expandIcon={
              <IconButton>
                {expanded === item.id ? <ExpandMoreIcon /> : <ExpandMoreIcon />}
              </IconButton>
            }
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography variant="h6">{item.question}</Typography>
            {editQuestion && (
              <div>
                <Tooltip title="Edit">
                  <IconButton onClick={(e) => editQuestion(e, item)}>
                    <EditCalendarIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={(e) => deleteQuestion(e, item)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </StyledAccordionSummary>
          <AccordionDetails>
            <List dense>
              <ListItem>
                <ListItemText primary="" secondary={item.answer} />
              </ListItem>
            </List>
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </div>
  );
};
